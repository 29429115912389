import React, { useEffect, useState } from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import { Tab } from "@headlessui/react";

const Menu = (props) => {
  const [data, setUsers] = useState([]);
  const [collection, setCollection] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const ASSETS = "https://admin.arabian-industries.net/api/storage/uploads";

  function replaceSpaceWithUnderscore(str) {
    return str.replaceAll(" ", "_");
  }
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const fetchUserData = () => {
    fetch(
      "https://admin.arabian-industries.net/api/api/collections/entry/pages/" +
        props.page +
        "?token=8c25a7a0819fe42e3a0ddb3adc68b1"
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        //console.log(data);
        setUsers(data);
        //
        if (data.collection) {
          // LOAD Collection
          fetch(
            "https://admin.arabian-industries.net/api/api/collections/get/" +
              replaceSpaceWithUnderscore(data.title.toLowerCase()) +
              "?token=8c25a7a0819fe42e3a0ddb3adc68b1"
          )
            .then((response) => {
              return response.json();
            })
            .then((data) => {
              //console.log(data.entries);
              setCollection(data.entries);
              setIsLoading(false);
            });
          //
        }
        //
      });
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <>
      {isLoading ? (
        <div
          className="loading"
          style={{
            position: "relative",
            height: "50vh",
            display: "flex",
            alignItems: "center",
          }}
        ></div>
      ) : (
        <React.Fragment key={props.page}>
          <div className="CollectionHolder">
            {data.collection ? (
              <Tab.Group>
                <div className="CollectionLeft">
                  {collection &&
                    collection.map((item, index) => (
                      <div key={index}>
                        <Tab.List>
                          <Tab
                            className={({ selected }) =>
                              classNames("btn", selected ? "active" : "")
                            }
                          >
                            {item.title}
                          </Tab>
                        </Tab.List>
                      </div>
                    ))}
                </div>
                <div className="CollectionRight">
                  {collection &&
                    collection.map((item, index) => (
                      <div key={index + `_collection2`}>
                        <Tab.Panels>
                          <Tab.Panel>
                            <div className="SubCollectionHolder">
                              <h1 className="MobileH1">{item.title}</h1>
                              <div
                                style={{
                                  fontSize: "15px",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html:
                                    item.content.substring(0, 300) + `...`,
                                }}
                              />
                              <div>
                                <Link
                                  key={item.title_slug}
                                  className="btnSend"
                                  state={{
                                    from: item._id,
                                  }}
                                  to={
                                    `/en/` +
                                    replaceSpaceWithUnderscore(
                                      data.collection.toLowerCase()
                                    ) +
                                    `/` +
                                    item.title_slug
                                  }
                                  title={`Find out more about ` + item.title}
                                >
                                  Find out more
                                </Link>
                              </div>
                            </div>

                            {item.image_tab ? (
                              <picture>
                                <source
                                  media="(min-width: 1180px)"
                                  srcSet={ASSETS + item.image_tab.path}
                                />
                                <source
                                  media="(max-width: 667px)"
                                  srcSet={ASSETS + item.image_mobile.path}
                                />
                                <img
                                  title={item.title}
                                  src={ASSETS + item.image_mobile.path}
                                  alt={item.title}
                                  className="MenuImgCollection"
                                  width="667"
                                  height="1024"
                                  loading="lazy"
                                  decoding="async"
                                />
                              </picture>
                            ) : (
                              <div></div>
                            )}
                          </Tab.Panel>
                        </Tab.Panels>
                      </div>
                    ))}
                </div>
              </Tab.Group>
            ) : (
              <div></div>
            )}
          </div>
        </React.Fragment>
      )}
    </>
  );
};

export default Menu;

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
}
