import React, { useEffect, useState } from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

import MobileCollection from "../../components/menu/mobileCont";
import MenuICON from "../../components/menu/icon";
import MenuPOP from "../../components/menu/mobilePop";
import Close from "../../images/close.svg";

import Contact from "../../images/contact-us-filled-svgrepo-com.svg";
import Call from "../../images/call-192-svgrepo-com.svg";

import { Disclosure } from "@headlessui/react";

import { Tab } from "@headlessui/react";

import "./mobile_menu.css";

function MobileMenu(props) {
  const [postmenu, setPostmenu] = React.useState();
  const [page, setPage] = React.useState();

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  function replaceSpaceWithUnderscore(str) {
    return str.replaceAll(" ", "-");
  }
  // MobileMenu
  function refreshPage() {
    var delayInMilliseconds = 1000; //1 second
    setTimeout(function () {
      window.location.reload(false);
    }, delayInMilliseconds);
  }
  //

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://admin.arabian-industries.net/api/api/singletons/get/settings?token=8c25a7a0819fe42e3a0ddb3adc68b1",
        {
          method: "get",
          headers: { "Content-Type": "application/json" },
        }
      );
      await response.json().then(function (response) {
        setPostmenu(response);
      });
    };
    fetchData();
    //
    const fetchDataPage = async () => {
      const response = await fetch(
        `https://admin.arabian-industries.net/api/api/collections/entries/pages?token=8c25a7a0819fe42e3a0ddb3adc68b1`,
        {
          method: "get",
          headers: { "Content-Type": "application/json" },
        }
      );
      await response.json().then(function (response) {
        setPage(response.entries);
        //console.log(response.entries);
      });
    };
    fetchDataPage();
    //
  }, []);

  if (!postmenu) return;
  if (!page) return;

  return (
    <div className="ok">
      {page &&
        page.map((item, index) => {
          return (
            <React.Fragment key={index + `_page`}>
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className={item.title_slug}>
                      {item.title}
                    </Disclosure.Button>
                    <Disclosure.Panel>
                      <div className="contentExt">
                        {/* SUB PAGE POP */}
                        {item.children ? (
                          <ul className="MobileULExt">
                            {item.children &&
                              item.children.map((item, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    <MenuPOP
                                      page={item._id}
                                      name={item.title}
                                    />
                                  </React.Fragment>
                                );
                              })}
                          </ul>
                        ) : (
                          <React.Fragment>
                            <MenuPOP page={item._id} name={item.title} />
                          </React.Fragment>
                        )}

                        {/* <MenuPOP
                          page={item._id}
                          name={item.title}
                          catSEND="pages"
                        /> */}

                        {/*  */}
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </React.Fragment>
          );
        })}
      <Link
        onClick={refreshPage}
        to="/en/contact-us"
        className="WWDLink"
        title="LET’S TALK"
        state={{
          from: "c18ff0253436318a9f00033c",
          catSEND: "pages",
        }}
        style={{
          width: "60px",
          height: "60px",
          marginBottom: "25px",
          marginTop: "25px",
          border: "none",
        }}
      >
        <img
          className="site-logo-loading"
          width="200"
          height="80"
          src={Contact}
          alt="Arabian Industries LLC | Oman"
          title="Arabian Industries LLC | Oman"
        />
      </Link>
      <a
        className="WWDLink"
        href="tel:+968 2205 6000"
        style={{
          width: "60px",
          height: "60px",
          marginBottom: "25px",
          marginTop: "25px",
          border: "none",
        }}
      >
        <img
          className="site-logo-loading"
          width="200"
          height="80"
          src={Call}
          alt="Arabian Industries LLC | Oman"
          title="Arabian Industries LLC | Oman"
        />
      </a>
    </div>
  );
}
export default MobileMenu;

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
}
