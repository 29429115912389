import React, { useEffect, useState } from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import MobileCollection from "../../components/menu/mobileCollection";

const Menu = (props) => {
  const [data, setUsers] = useState([]);
  const [parent, setParent] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const ASSETS = "https://admin.arabian-industries.net/api/storage/uploads";

  function replaceSpaceWithUnderscore(str) {
    return str.replaceAll(" ", "_");
  }

  const fetchUserData = () => {
    fetch(
      "https://admin.arabian-industries.net/api/api/collections/entry/pages/" +
        props.page +
        "?token=8c25a7a0819fe42e3a0ddb3adc68b1"
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        //console.log(data);
        setUsers(data);
        setIsLoading(false);

        if (data._pid) {
          // SEC
          fetch(
            "https://admin.arabian-industries.net/api/api/collections/entry/pages/" +
              data._pid +
              "?token=8c25a7a0819fe42e3a0ddb3adc68b1"
          )
            .then((response) => {
              return response.json();
            })
            .then((data) => {
              //console.log("parent", data.title_slug);
              setParent(data);
            });
          //
        }
      });
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <>
      {isLoading ? (
        <div
          className="loading"
          style={{
            position: "relative",
            height: "50vh",
            display: "flex",
            alignItems: "center",
          }}
        ></div>
      ) : (
        <>
          <div className="MobileDataHolder">
            {data.collection ? (
              <MobileCollection page={data._id} />
            ) : (
              <div className="DataH">
                <div className="Left">
                  <h1 className="MobileH1">{data.title}</h1>
                  <div
                    style={{
                      fontSize: "15px",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: data.content.substring(0, 300) + `...`,
                    }}
                  />
                  <div>
                    {parent.title_slug ? (
                      <Link
                        key={data.title_slug}
                        className="btnSend"
                        state={{ from: data._id, catSEND: "pages" }}
                        to={`/en/` + parent.title_slug + `/` + data.title_slug}
                        title={`Find out more about ` + data.title}
                      >
                        Find out more
                      </Link>
                    ) : (
                      <Link
                        key={data.title_slug}
                        className="btnSend"
                        state={{ from: data._id, catSEND: "pages" }}
                        to={`/en/` + data.title_slug}
                        title={`Find out more about ` + data.title}
                      >
                        Find out more
                      </Link>
                    )}
                  </div>
                </div>
                <div className="Right">
                  {data.image_menu ? (
                    <React.Fragment>
                      <picture>
                        <source
                          media="(min-width: 1180px)"
                          srcSet={ASSETS + data.image_menu.path}
                        />
                        <source
                          media="(max-width: 667px)"
                          srcSet={ASSETS + data.image_menu.path}
                        />
                        <img
                          title={data.title}
                          src={ASSETS + data.image_menu.path}
                          alt={data.title}
                          className="MenuImgCollectionSUB"
                          width="667"
                          height="1024"
                          loading="lazy"
                          decoding="async"
                        />
                      </picture>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <picture>
                        <source
                          media="(min-width: 1180px)"
                          srcSet={ASSETS + data.image_tab.path}
                        />
                        <source
                          media="(max-width: 667px)"
                          srcSet={ASSETS + data.image_mobile.path}
                        />
                        <img
                          title={data.title}
                          src={ASSETS + data.image_mobile.path}
                          alt={data.title}
                          className="MenuImgCollectionSUB"
                          width="667"
                          height="1024"
                          loading="lazy"
                          decoding="async"
                        />
                      </picture>
                    </React.Fragment>
                  )}
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Menu;

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
}
