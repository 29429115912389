import React, { Suspense, lazy, useEffect, useState, useRef } from "react";
import Search from "./components/Search";
import SearchAr from "./ar_components/SearchAr";

import {
  Routes,
  Route,
  Navigate,
  Link,
  useMatch,
  useResolvedPath,
  useLocation,
  useParams,
} from "react-router-dom";
import { Tab } from "@headlessui/react";

import Logo from "./images/Logo.svg";
import LogoW from "./images/LogoW.svg";
import BGEffectsFOOT from "./images/bgARt_Footer.svg";

import MobileMenuDrag from "./pages/menu/mobile";
import MobileMenuDrag_Ar from "./ar_pages/menu/mobile";

// COMPON
// const Menu = lazy(() => import("./components/menu/index"));
const MenuICON = lazy(() => import("./components/menu/icon"));
const MenuCollection = lazy(() => import("./components/menu/collection"));
// const MenuFooter = lazy(() => import("./components/menu/footer"));
const MobileMenu = lazy(() => import("./pages/menu/index"));
const Home = lazy(() => import("./pages/home/index"));
const Page = lazy(() => import("./pages/page/index"));
const PageNoLocation = lazy(() => import("./pages/page/indexNOLocation"));
const GroupCompanies = lazy(() => import("./pages/page/GroupCompanies"));
const GroupCompaniesNoLocation = lazy(() =>
  import("./pages/page/GroupCompaniesNOLocation")
);
const PrivacyPolicy = lazy(() => import("./pages/page/policy"));
const CaseStudy = lazy(() => import("./pages/page/CaseStudy"));
const OurPeople = lazy(() => import("./pages/page/OurPeople"));
const News = lazy(() => import("./pages/page/News"));
const Job = lazy(() => import("./pages/page/Job"));
const JobApply = lazy(() => import("./pages/page/JobApply"));
const VerifyJobOffers = lazy(() => import("./pages/page/VerifyJobOffers"));
const VerifyJobOffers_Ar = lazy(() =>
  import("./ar_pages/page/VerifyJobOffers")
);

// ARABIC
// const Menu_Ar = lazy(() => import("./ar_components/menu/index"));
const MenuICON_Ar = lazy(() => import("./ar_components/menu/icon"));
const MenuCollection_Ar = lazy(() => import("./ar_components/menu/collection"));
// const MenuFooter_Ar = lazy(() => import("./ar_components/menu/footer"));
const MobileMenu_Ar = lazy(() => import("./ar_pages/menu/index"));
const Home_Ar = lazy(() => import("./ar_pages/home/index"));
const Page_Ar = lazy(() => import("./ar_pages/page/index"));
const PageNoLocation_Ar = lazy(() => import("./ar_pages/page/indexNOLocation"));
const GroupCompanies_Ar = lazy(() => import("./ar_pages/page/GroupCompanies"));
const GroupCompaniesNoLocation_Ar = lazy(() =>
  import("./ar_pages/page/GroupCompaniesNOLocation")
);
const PrivacyPolicy_Ar = lazy(() => import("./ar_pages/page/policy"));
const CaseStudy_Ar = lazy(() => import("./ar_pages/page/CaseStudy"));
const OurPeople_Ar = lazy(() => import("./ar_pages/page/OurPeople"));
const News_Ar = lazy(() => import("./ar_pages/page/News"));
const Job_Ar = lazy(() => import("./ar_pages/page/Job"));
const JobApply_Ar = lazy(() => import("./ar_pages/page/JobApply"));
//

function App() {
  // SEARCH
  const [isShown, setIsShown] = useState(false);
  const handleClick = (event) => {
    // 👇️ toggle shown state
    setIsShown((current) => !current);
    // 👇️ or simply set it to true
    // setIsShown(true);
  };
  //

  // MobileMenu
  const [isShownM, setIsShownM] = useState(false);
  const handleClickM = (event) => {
    // 👇️ toggle shown state
    setIsShownM((current) => !current);
    // 👇️ or simply set it to true
    //setIsShownM(true);
  };
  //

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  //
  // SCROLL
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  //

  const [post, setPost] = React.useState();
  const [page, setPage] = React.useState();

  function replaceSpaceWithUnderscore(str) {
    return str.replaceAll(" ", "-");
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const location = useLocation();
  // if (location.state) {
  //   console.log(location.state.from);
  // } else {
  //   console.log("NO");
  // }

  //console.log(window.location.pathname); //yields: "/js" (where snippets run)

  // Get the userId param from the URL.
  let { catslug } = useParams();
  // ...

  //console.log(location.pathname.split("/")[1]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        `https://admin.arabian-industries.net/api/api/singletons/get/home?token=8c25a7a0819fe42e3a0ddb3adc68b1`,
        {
          method: "get",
          headers: { "Content-Type": "application/json" },
        }
      );
      await response.json().then(function (response) {
        setPost(response);
        //console.log("settings", response);
      });
    };
    fetchData();
    //
    const fetchDataPage = async () => {
      const response = await fetch(
        `https://admin.arabian-industries.net/api/api/collections/entries/pages?token=8c25a7a0819fe42e3a0ddb3adc68b1`,
        {
          method: "get",
          headers: { "Content-Type": "application/json" },
        }
      );
      await response.json().then(function (response) {
        setPage(response.entries);
        //console.log(response.entries);
      });
    };
    fetchDataPage();
    //
  }, []);

  if (!post) return;
  if (!page) return;

  return (
    <>
      {isShownM && (
        <div className="DrageMobileMenu">
          <button
            className="CloseBtn"
            onClick={handleClickM}
            style={{
              position: "fixed",
              color: "white",
              top: "20px",
              zIndex: "10",
            }}
          >
            &#10005;
          </button>
          {location.pathname.split("/")[1] === "en" ? (
            <MobileMenuDrag />
          ) : (
            <MobileMenuDrag_Ar />
          )}
        </div>
      )}
      {isShown && (
        <div className="POPSearch">
          <div className="SerchBar">
            <img
              className="site-logo-loading"
              width="200"
              height="80"
              src={Logo}
              alt="Arabian Industries LLC | Oman"
              title="Arabian Industries LLC | Oman"
            />

            <div
              className="InrDiv"
              style={{
                width: "100%",
              }}
            >
              {location.pathname.split("/")[1] === "en" ? (
                <Search />
              ) : (
                <SearchAr />
              )}
            </div>
            <button className="CloseBtn" onClick={handleClick}>
              &#10005;
            </button>
          </div>
        </div>
      )}

      {location.pathname.split("/")[1] === "en" ? (
        //Englishhhhhhhhhhhhhhhhhhhhhhh Menu
        <div
          className="Sticky"
          style={{
            boxShadow:
              scrollPosition == "0"
                ? "none"
                : "rgb(0 0 0 / 4%) 0px 5px 5px 0px",
            transition: scrollPosition == "0" ? "0.2s" : "0.2s",
          }}
        >
          <nav
            className="nav"
            style={{
              height: scrollPosition == "0" ? "120px" : "auto",
              paddingTop: scrollPosition == "0" ? "15px" : "0",
              paddingBottom: scrollPosition == "0" ? "15px" : "0",
              transition: scrollPosition == "0" ? "0.2s" : "0.2s",
            }}
          >
            <Link
              rel="alternate"
              hrefLang="en"
              key={post._id}
              to="/en/arabian-industries"
              className="site-title"
              // onClick="window.location.reload();"
            >
              <picture>
                <source srcSet={LogoW} media="(prefers-color-scheme: dark)" />
                <img
                  className="site-logo-loading"
                  width="200"
                  height="80"
                  src={Logo}
                  alt="Arabian Industries LLC | Oman"
                  title="Arabian Industries LLC | Oman"
                />
              </picture>
            </Link>
            <div className="MasterMenu">
              {/* MENU */}
              <ul className="TopMenu">
                {/* Who We Are */}
                {page &&
                  page.map((item, index) => {
                    return (
                      <ul
                        className="popover popover-bottom"
                        key={index + `_popover`}
                      >
                        {item.children.length > 0 ? (
                          <CustomLink
                            className={
                              item.menu_color === "Light" ? "Light" : "Dark"
                            }
                            title={item.title}
                            to={
                              `/en/` +
                              replaceSpaceWithUnderscore(
                                item.title_slug.toLowerCase()
                              ) +
                              `/` +
                              item.children[0].title_slug
                            }
                            state={{
                              from: item.children[0]._id,
                              name: item.children[0].title_slug,
                              catSEND: "pages",
                            }}
                          >
                            {item.title}
                          </CustomLink>
                        ) : (
                          <CustomLink
                            className={
                              item.menu_color === "Light" ? "Light" : "Dark"
                            }
                            title={item.title}
                            to={`/en/` + item.title_slug}
                            state={{ from: item._id, catSEND: "pages" }}
                          >
                            {item.title}
                          </CustomLink>
                        )}

                        {item.children.length > 0 ? (
                          <div
                            style={{
                              paddingTop:
                                scrollPosition == "0" ? "48px" : "23px",
                              top: scrollPosition == "0" ? "72px" : "50px",
                              // transition: scrollPosition == "0" ? "0.2s" : "0.2s",
                            }}
                            className="popover-container"
                          >
                            <div className="DesignMenu">
                              <Tab.Group>
                                <React.Fragment>
                                  <Tab.List className="TabList">
                                    {item.children &&
                                      item.children.map((item, index) => {
                                        return (
                                          <React.Fragment key={index}>
                                            <Tab
                                              className={({ selected }) =>
                                                classNames(
                                                  "btn",
                                                  selected ? "active" : ""
                                                )
                                              }
                                              title={
                                                `Click to expand ` + item.title
                                              }
                                            >
                                              <MenuICON
                                                page={item._id}
                                                name={item.title}
                                              />
                                            </Tab>
                                          </React.Fragment>
                                        );
                                      })}
                                  </Tab.List>

                                  <Tab.Panels className="TabPanels">
                                    {item.children &&
                                      item.children.map((item, index) => {
                                        return (
                                          <React.Fragment
                                            key={index + `_children`}
                                          >
                                            <Tab.Panel
                                              key={`Tab.Panel_` + item._id}
                                            >
                                              <MenuCollection page={item._id} />
                                            </Tab.Panel>
                                          </React.Fragment>
                                        );
                                      })}
                                  </Tab.Panels>
                                </React.Fragment>
                              </Tab.Group>
                            </div>
                          </div>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}
                      </ul>
                    );
                  })}
              </ul>
              {/*  */}
              {/* SEARCH */}
              <div
                onClick={handleClick}
                className="site-search"
                width="25"
                height="25"
                alt="Arabian Industries LLC | Search Here"
                title="Arabian Industries LLC | Search Here"
              ></div>
              {/*  */}
              {/* Language */}
              <div className="LangAR">
                <Link
                  rel="alternate"
                  hrefLang="ar"
                  key="ar"
                  to="/ar/"
                  className="site-title"
                  // onClick="window.location.reload();"
                >
                  عـربـي
                </Link>
              </div>
              {/* moble Menu */}
              <ul
                className="MMenu"
                style={{
                  listStyle: "none",
                  padding: "0",
                }}
              >
                <div
                  onClick={handleClickM}
                  className="site-mobile"
                  width="48"
                  height="48"
                  alt="Arabian Industries LLC | Menu"
                  title="Arabian Industries LLC | Menu"
                ></div>
              </ul>

              {/*  */}

              {/* Tab Menu */}
              <ul
                className="MobileMenu"
                style={{
                  listStyle: "none",
                  padding: "0",
                }}
              >
                <CustomLink title="Arabian Industries LLC | Menu" to="/en/menu">
                  <div
                    className="site-mobile"
                    width="48"
                    height="48"
                    alt="Arabian Industries LLC | Menu"
                    title="Arabian Industries LLC | Menu"
                  ></div>
                </CustomLink>
              </ul>
              {/*  */}
            </div>
          </nav>
        </div>
      ) : (
        // Arabicccccccccccccccccccccccc Menu
        <div
          className="Sticky arabic"
          style={{
            boxShadow:
              scrollPosition == "0"
                ? "none"
                : "rgb(0 0 0 / 4%) 0px 5px 5px 0px",
            transition: scrollPosition == "0" ? "0.2s" : "0.2s",
          }}
        >
          <nav
            className="nav"
            style={{
              height: scrollPosition == "0" ? "120px" : "auto",
              paddingTop: scrollPosition == "0" ? "15px" : "0",
              paddingBottom: scrollPosition == "0" ? "15px" : "0",
              transition: scrollPosition == "0" ? "0.2s" : "0.2s",
            }}
          >
            <Link
              rel="alternate"
              hrefLang="en"
              key={post._id}
              to="/ar/arabian-industries"
              className="site-title"
              // onClick="window.location.reload();"
            >
              <picture>
                <source srcSet={LogoW} media="(prefers-color-scheme: dark)" />
                <img
                  className="site-logo-loading"
                  width="200"
                  height="80"
                  src={Logo}
                  alt="Arabian Industries LLC | Oman"
                  title="Arabian Industries LLC | Oman"
                />
              </picture>
            </Link>
            <div className="MasterMenu">
              {/* Arabiccccccccccccc MENU */}
              <ul className="TopMenu">
                {/* Who We Are */}
                {page &&
                  page.map((item, index) => {
                    return (
                      <ul
                        className="popover popover-bottom"
                        key={index + `_popover`}
                      >
                        {item.children.length > 0 ? (
                          <CustomLink
                            className={
                              item.menu_color === "Light" ? "Light" : "Dark"
                            }
                            title={item.title_ar}
                            to={
                              `/ar/` +
                              replaceSpaceWithUnderscore(
                                item.title_slug.toLowerCase()
                              ) +
                              `/` +
                              item.children[0].title_slug
                            }
                            state={{
                              from: item.children[0]._id,
                              name: item.children[0].title_slug,
                              catSEND: "pages",
                            }}
                          >
                            {item.title_ar}
                          </CustomLink>
                        ) : (
                          <CustomLink
                            className={
                              item.menu_color === "Light" ? "Light" : "Dark"
                            }
                            title={item.title_ar}
                            to={`/ar/` + item.title_slug}
                            state={{ from: item._id, catSEND: "pages" }}
                          >
                            {item.title_ar}
                          </CustomLink>
                        )}

                        {item.children.length > 0 ? (
                          <div
                            style={{
                              paddingTop:
                                scrollPosition == "0" ? "48px" : "23px",
                              top: scrollPosition == "0" ? "72px" : "50px",
                              // transition: scrollPosition == "0" ? "0.2s" : "0.2s",
                            }}
                            className="popover-container"
                          >
                            <div className="DesignMenu">
                              <Tab.Group>
                                <React.Fragment>
                                  <Tab.List className="TabList_ar">
                                    {item.children &&
                                      item.children.map((item, index) => {
                                        return (
                                          <React.Fragment key={index}>
                                            <Tab
                                              className={({ selected }) =>
                                                classNames(
                                                  "btn",
                                                  selected ? "active" : ""
                                                )
                                              }
                                              title={
                                                `اضغط للمزيد-` + item.title_ar
                                              }
                                            >
                                              <MenuICON_Ar
                                                page={item._id}
                                                name={item.title_ar}
                                              />
                                            </Tab>
                                          </React.Fragment>
                                        );
                                      })}
                                  </Tab.List>

                                  <Tab.Panels className="TabPanels">
                                    {item.children &&
                                      item.children.map((item, index) => {
                                        return (
                                          <React.Fragment
                                            key={index + `_children`}
                                          >
                                            <Tab.Panel
                                              key={`Tab.Panel_` + item._id}
                                            >
                                              <MenuCollection_Ar
                                                page={item._id}
                                              />
                                            </Tab.Panel>
                                          </React.Fragment>
                                        );
                                      })}
                                  </Tab.Panels>
                                </React.Fragment>
                              </Tab.Group>
                            </div>
                          </div>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}
                      </ul>
                    );
                  })}
              </ul>
              {/*  */}
              {/* SEARCH */}
              <div
                onClick={handleClick}
                className="site-search"
                width="25"
                height="25"
                alt="Arabian Industries LLC | Search Here"
                title="Arabian Industries LLC | Search Here"
              ></div>
              {/*  */}
              {/* Language */}
              <div className="LangEn">
                <Link
                  rel="alternate"
                  hrefLang="ar"
                  key="en"
                  to="/"
                  className="site-title"
                  // onClick="window.location.reload();"
                >
                  English
                </Link>
              </div>
              {/* moble Menu */}
              <ul
                className="MMenu"
                style={{
                  listStyle: "none",
                  padding: "0",
                }}
              >
                <div
                  onClick={handleClickM}
                  className="site-mobile"
                  width="48"
                  height="48"
                  alt="Arabian Industries LLC | Menu"
                  title="Arabian Industries LLC | Menu"
                ></div>
              </ul>

              {/*  */}

              {/* Tab Menu */}
              <ul
                className="MobileMenu"
                style={{
                  listStyle: "none",
                  padding: "0",
                }}
              >
                <CustomLink title="Arabian Industries LLC | Menu" to="/en/menu">
                  <div
                    className="site-mobile"
                    width="48"
                    height="48"
                    alt="Arabian Industries LLC | Menu"
                    title="Arabian Industries LLC | Menu"
                  ></div>
                </CustomLink>
              </ul>
              {/*  */}
            </div>
          </nav>
        </div>
      )}

      <div
        className={
          location.pathname.split("/")[1] === "en"
            ? "container body"
            : "container body arabic"
        }
        onScroll={handleScroll}
      >
        <Suspense fallback={<div className="loading loading-lg"></div>}>
          <Routes forceRefresh={true}>
            <Route
              path="/"
              element={<Navigate to="/en/arabian-industries" />}
            />
            <Route
              path="/en"
              element={<Navigate to="/en/arabian-industries" />}
            />
            <Route
              path="/ar/"
              element={<Navigate to="/ar/arabian-industries" />}
            />

            {location.state ? (
              <React.Fragment>
                <Route path="/en/:slug" element={<Page />} />
                <Route path="/ar/:slug" element={<Page_Ar />} />
                <Route path="/en/:slug/:subslug" element={<Page />} />
                <Route
                  path="/en/group_companies/:subslug"
                  element={<GroupCompanies />}
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Route path="/en/:slug" element={<PageNoLocation />} />
                <Route path="/en/:slug/:subslug" element={<PageNoLocation />} />
                <Route
                  path="/en/group_companies/:subslug"
                  element={<GroupCompaniesNoLocation />}
                />
              </React.Fragment>
            )}

            {location.state ? (
              <React.Fragment>
                <Route path="/ar/:slug" element={<Page_Ar />} />
                <Route path="/ar/:slug/:subslug" element={<Page_Ar />} />
                <Route
                  path="/ar/group_companies/:subslug"
                  element={<GroupCompanies_Ar />}
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Route path="/ar/:slug" element={<PageNoLocation_Ar />} />
                <Route
                  path="/ar/:slug/:subslug"
                  element={<PageNoLocation_Ar />}
                />
                <Route
                  path="/ar/group_companies/:subslug"
                  element={<GroupCompaniesNoLocation_Ar />}
                />
              </React.Fragment>
            )}

            <Route path="/en/arabian-industries" element={<Home />} />
            <Route path="/ar/arabian-industries" element={<Home_Ar />} />

            <Route path="/en/careers/job-openings/:job" element={<Job />} />
            <Route path="/ar/careers/job-openings/:job" element={<Job_Ar />} />

            <Route
              path="/en/careers/Verify-Job-Offers/"
              element={<VerifyJobOffers />}
            />
            <Route
              path="/ar/careers/Verify-Job-Offers"
              element={<VerifyJobOffers_Ar />}
            />
            <Route
              path="/en/careers/job-openings/:job/apply"
              element={<JobApply />}
            />
            <Route
              path="/ar/careers/job-openings/:job/apply"
              element={<JobApply_Ar />}
            />
            <Route
              path="/en/services/case-studies/:cs"
              element={<CaseStudy />}
            />
            <Route
              path="/ar/services/case-studies/:cs"
              element={<CaseStudy_Ar />}
            />
            <Route path="/en/careers/our-people/:op" element={<OurPeople />} />
            <Route
              path="/ar/careers/our-people/:op"
              element={<OurPeople_Ar />}
            />
            <Route path="/en/media/news/:news" element={<News />} />
            <Route path="/ar/media/news/:news" element={<News_Ar />} />
            <Route path="/en/menu" element={<MobileMenu />} />
            <Route path="/ar/menu" element={<MobileMenu_Ar />} />
            <Route path="/en/privacy_policy" element={<PrivacyPolicy />} />
            <Route path="/ar/privacy_policy" element={<PrivacyPolicy_Ar />} />
            {/* <Route path="*" element={<NotFound />} /> */}
          </Routes>
        </Suspense>
      </div>

      {location.pathname.split("/")[1] === "en" ? (
        <footer className="footer">
          <div className="FooterBin">
            <div className="TOPFoot">
              <div className="FLeft">
                <img
                  className="site-logo-loading"
                  width="200"
                  height="80"
                  src={LogoW}
                  alt="Arabian Industries LLC | Oman"
                  title="Arabian Industries LLC | Oman"
                />
              </div>
              <div className="FRight">
                <a
                  href="https://www.linkedin.com/company/arabian-industries/"
                  target="_blank"
                  title="https://www.linkedin.com/company/arabian-industries/"
                >
                  <div
                    className="linkedin"
                    width="25"
                    height="25"
                    alt="Arabian Industries LLC | Linkedin"
                    title="Arabian Industries LLC | Linkedin"
                  ></div>
                </a>
              </div>
            </div>

            {/* FOOTER MENU */}
            <ul className="FootMenu">
              <div className="BlockF">
                <div className="Parent">
                  <CustomLink
                    title="WHO WE ARE"
                    to="/en/who-we-are/what-we-do"
                    state={{
                      from: "8013fcbe31643963a10000ee",
                      catSEND: "pages",
                    }}
                    className="Parent"
                  >
                    WHO WE ARE
                  </CustomLink>
                </div>
                <div className="Child">
                  <CustomLink
                    title="What We Do"
                    to="/en/who-we-are/what-we-do"
                    state={{
                      from: "8013fcbe31643963a10000ee",
                      catSEND: "pages",
                    }}
                    className="Child"
                  >
                    What We Do
                  </CustomLink>
                  <CustomLink
                    title="Our Story"
                    to="/en/who-we-are/our-story"
                    state={{
                      from: "80a07ac565346454d000016a",
                      catSEND: "pages",
                    }}
                    className="Child"
                  >
                    Our Story
                  </CustomLink>
                  <CustomLink
                    title="Group Companies"
                    to="/en/group_companies/overview"
                    state={{
                      from: "8257ecbf363066b867000236",
                      catSEND: "group_companies",
                    }}
                    className="Parent Last"
                  >
                    Group Companies
                  </CustomLink>
                  <div className="subHolder">
                    <CustomLink
                      title="Overview"
                      to="/en/group_companies/overview"
                      state={{
                        from: "8257ecbf363066b867000236",
                        catSEND: "group_companies",
                      }}
                      className="ParentSub"
                    >
                      Overview
                    </CustomLink>
                    <CustomLink
                      title="AI Clad LLC"
                      to="/en/group_companies/ai-clad-llc"
                      state={{
                        from: "829ac6af36376170980001ad",
                        catSEND: "group_companies",
                      }}
                      className="ParentSub"
                    >
                      AI Clad LLC
                    </CustomLink>
                    <CustomLink
                      title="Arabian Industries Manufacturing LLC (AIM)"
                      to="/en/group_companies/arabian-industries-manufacturing-llc-aim"
                      state={{
                        from: "b3539f91613837ba97000143",
                        catSEND: "group_companies",
                      }}
                      className="ParentSub"
                    >
                      Arabian Industries Manufacturing LLC (AIM)
                    </CustomLink>
                    <CustomLink
                      title="Arabian Industries Projects LLC"
                      to="/en/group_companies/arabian-industries-projects-llc"
                      state={{
                        from: "bfcbb3733266614e3d000247",
                        catSEND: "group_companies",
                      }}
                      className="ParentSub"
                    >
                      Arabian Industries Projects LLC
                    </CustomLink>
                    <CustomLink
                      title="Arabian Industries Technical Support LLC"
                      to="/en/group_companies/arabian-industries-technical-support-llc"
                      state={{
                        from: "bfd8d29b353436546d000061",
                        catSEND: "group_companies",
                      }}
                      className="ParentSub"
                    >
                      Arabian Industries Technical Support LLC
                    </CustomLink>
                    <CustomLink
                      title="Arabian Industries Joint Ventures"
                      to="/en/group_companies/arabian-industries-joint-ventures"
                      state={{
                        from: "bfefd6ec33613080740000b5",
                        catSEND: "group_companies",
                      }}
                      className="ParentSub"
                    >
                      Arabian Industries Joint Ventures
                    </CustomLink>
                    <CustomLink
                      title="Arabian Industries Joint Ventures"
                      to="/en/group_companies/technical-rubber-llc-nektel"
                      state={{
                        from: "c306fb79613530fe930002df",
                        catSEND: "group_companies",
                      }}
                      className="ParentSub"
                    >
                      Technical Rubber LLC (NEKTEL)
                    </CustomLink>
                    <CustomLink
                      title="The Oman Construction Company (TOCO) LLC"
                      to="/en/group_companies/the-oman-construction-company-toco-llc"
                      state={{
                        from: "c32162513138312a8c0003d0",
                        catSEND: "group_companies",
                      }}
                      className="ParentSub"
                    >
                      The Oman Construction Company (TOCO) LLC
                    </CustomLink>
                  </div>
                </div>
                <div className="Child">
                  <CustomLink
                    title="Resources & Infrastructure"
                    to="/en/who-we-are/resources-infrastructure"
                    state={{
                      from: "80ef2576633532f4500001b2",
                      catSEND: "pages",
                    }}
                    className="Child"
                  >
                    Resources & Infrastructure
                  </CustomLink>
                  <CustomLink
                    title="Our Vision & Values"
                    to="/en/who-we-are/our-vision-values"
                    state={{
                      from: "80fb3fb1623963970b00002d",
                      catSEND: "pages",
                    }}
                    className="Child"
                  >
                    Our Vision & Values
                  </CustomLink>
                  <CustomLink
                    title="Awards & Accreditations"
                    to="/en/who-we-are/awards-accreditations"
                    state={{
                      from: "811c97616464370b8000016f",
                      catSEND: "pages",
                    }}
                    className="Child"
                  >
                    Awards & Accreditations
                  </CustomLink>
                </div>
              </div>
              {/*  */}
              <div className="BlockF">
                <div className="Parent">
                  <CustomLink
                    title="SERVICES"
                    to="/en/services/overview"
                    state={{
                      from: "b40f44d8393339c2d500010f",
                      catSEND: "pages",
                    }}
                    className="Parent"
                  >
                    SERVICES
                  </CustomLink>
                </div>
                <div className="Child">
                  <CustomLink
                    title="Overview"
                    to="/en/services/overview"
                    state={{
                      from: "b40f44d8393339c2d500010f",
                      catSEND: "pages",
                    }}
                    className="Child"
                  >
                    Overview
                  </CustomLink>

                  <CustomLink
                    title="EPC Services"
                    to="/en/epc_services/overview"
                    state={{
                      from: "b9e5d065366131aef9000144",
                      catSEND: "epc_services",
                    }}
                    className="Parent Last"
                  >
                    EPC Services
                  </CustomLink>
                  <div className="subHolder">
                    <CustomLink
                      title="Overview"
                      to="/en/epc_services/overview"
                      state={{
                        from: "b9e5d065366131aef9000144",
                        catSEND: "epc_services",
                      }}
                      className="ParentSub"
                    >
                      Overview
                    </CustomLink>
                    <CustomLink
                      title="Engineering & Procurement Services"
                      to="/en/epc_services/engineering-procurement-services"
                      state={{
                        from: "3603db416432344bae000013",
                        catSEND: "epc_services",
                      }}
                      className="ParentSub"
                    >
                      Engineering & Procurement Services
                    </CustomLink>
                    <CustomLink
                      title="Construction Services"
                      to="/en/epc_services/construction-services"
                      state={{
                        from: "581dde7d32356468ef000115",
                        catSEND: "epc_services",
                      }}
                      className="ParentSub"
                    >
                      Construction Services
                    </CustomLink>
                    <CustomLink
                      title="EPC of Pipelines"
                      to="/en/epc_services/epc-of-pipelines"
                      state={{
                        from: "5931a1ed383230ec45000329",
                        catSEND: "epc_services",
                      }}
                      className="ParentSub"
                    >
                      EPC of Pipelines
                    </CustomLink>
                    <CustomLink
                      title="Offshore EPCC Services"
                      to="/en/epc_services/offshore-epcc-services"
                      state={{
                        from: "5aac4d39653131aa29000284",
                        catSEND: "epc_services",
                      }}
                      className="ParentSub"
                    >
                      Offshore EPCC Services
                    </CustomLink>
                    <CustomLink
                      title="Brownfield Services"
                      to="/en/epc_services/brownfield-services"
                      state={{
                        from: "5b48f8a9306630835200018c",
                        catSEND: "epc_services",
                      }}
                      className="ParentSub"
                    >
                      Brownfield Services
                    </CustomLink>
                    <CustomLink
                      title="Commissioning & Start-up"
                      to="/en/epc_services/commissioning-start-up"
                      state={{
                        from: "5c272ec232313935ff000045",
                        catSEND: "epc_services",
                      }}
                      className="ParentSub"
                    >
                      Commissioning & Start-up
                    </CustomLink>
                  </div>
                </div>
                <div className="Child">
                  <CustomLink
                    title="Maintenance & Asset Integrity"
                    to="/en/services/maintenance-asset-integrity"
                    state={{
                      from: "b427dcb33932663bcb00006b",
                      catSEND: "pages",
                    }}
                    className="Child"
                  >
                    Maintenance & Asset Integrity
                  </CustomLink>

                  <CustomLink
                    title="Shutdowns & Turnarounds"
                    to="/en/services/shutdowns-turnarounds"
                    state={{
                      from: "b436d32261613966970003cd",
                      catSEND: "pages",
                    }}
                    className="Child"
                  >
                    Shutdowns & Turnarounds
                  </CustomLink>
                  <CustomLink
                    title="Workshop Services"
                    to="/en/workshop_services/overview"
                    state={{
                      from: "b44cef9436396485e20003bc",
                      catSEND: "workshop_services",
                    }}
                    className="Parent Last"
                  >
                    Workshop Services
                  </CustomLink>
                  <div className="subHolder">
                    <CustomLink
                      title="Overview"
                      to="/en/workshop_services/overview"
                      state={{
                        from: "b44cef9436396485e20003bc",
                        catSEND: "workshop_services",
                      }}
                      className="ParentSub"
                    >
                      Overview
                    </CustomLink>
                    <CustomLink
                      title="Fabrication Services"
                      to="/en/workshop_services/fabrication-services"
                      state={{
                        from: "63d54531386363cf33000041",
                        catSEND: "workshop_services",
                      }}
                      className="ParentSub"
                    >
                      Fabrication Services
                    </CustomLink>
                    <CustomLink
                      title="Coating & Lining Services"
                      to="/en/workshop_services/coating-lining-services"
                      state={{
                        from: "646c2766366463cb0a000390",
                        catSEND: "workshop_services",
                      }}
                      className="ParentSub"
                    >
                      Coating & Lining Services
                    </CustomLink>
                    <CustomLink
                      title="CRA Weld Overlay Cladding"
                      to="/en/workshop_services/cra-weld-overlay-cladding"
                      state={{
                        from: "65481f336639362f3d000231",
                        catSEND: "workshop_services",
                      }}
                      className="ParentSub"
                    >
                      CRA Weld Overlay Cladding
                    </CustomLink>
                    <CustomLink
                      title="Oilfield Equipment Maintenance"
                      to="/en/workshop_services/oilfield-equipment-maintenance"
                      state={{
                        from: "65cce55e613462f53f000080",
                        catSEND: "workshop_services",
                      }}
                      className="ParentSub"
                    >
                      Oilfield Equipment Maintenance
                    </CustomLink>
                    <CustomLink
                      title="Valve Maintenance"
                      to="/en/workshop_services/valve-maintenance"
                      state={{
                        from: "664bd90c6539374d4000003e",
                        catSEND: "workshop_services",
                      }}
                      className="ParentSub"
                    >
                      Valve Maintenance
                    </CustomLink>
                    <CustomLink
                      title="Equipment Maintenance Services"
                      to="/en/workshop_services/equipment-maintenance-services"
                      state={{
                        from: "6714d0363036392b010000a9",
                        catSEND: "workshop_services",
                      }}
                      className="ParentSub"
                    >
                      Equipment Maintenance Services
                    </CustomLink>
                    <CustomLink
                      title="Repair, Testing & Calibration of Instruments"
                      to="/en/workshop_services/repair-testing-calibration-of-instruments"
                      state={{
                        from: "67aad654646331adbf000346",
                        catSEND: "workshop_services",
                      }}
                      className="ParentSub"
                    >
                      Repair, Testing & Calibration of Instruments
                    </CustomLink>
                  </div>
                  <CustomLink
                    title="Logistics & Rig Moving Services"
                    to="/en/services/logistics-rig-moving-services"
                    state={{
                      from: "ba2f4a27653739803f00031d",
                      catSEND: "pages",
                    }}
                    className="ParentSub"
                  >
                    Logistics & Rig Moving Services
                  </CustomLink>
                  <CustomLink
                    title="Support Services"
                    to="/en/services/support-services"
                    state={{
                      from: "ba52c2833536632ea600032d",
                      catSEND: "pages",
                    }}
                    className="ParentSub"
                  >
                    Support Services
                  </CustomLink>
                  <CustomLink
                    title="Case Studies"
                    to="/en/services/case-studies"
                    state={{
                      from: "ba5d7f7233343489810001cd",
                      catSEND: "pages",
                    }}
                    className="ParentSub"
                  >
                    Case Studies
                  </CustomLink>
                </div>
              </div>
              {/*  */}
              <div className="BlockF MIDD">
                <div className="AA">
                  <div className="Parent">
                    <CustomLink
                      title="Our Products"
                      to="/en/products/overview"
                      state={{
                        from: "bb490807303638e3d500029d",
                        catSEND: "pages",
                      }}
                      className="Parent"
                    >
                      Our Products
                    </CustomLink>
                  </div>
                  <div className="Child">
                    <CustomLink
                      title="Overview"
                      to="/en/products/overview"
                      state={{
                        from: "bb490807303638e3d500029d",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      Overview
                    </CustomLink>
                    <CustomLink
                      title="Process Equipment"
                      to="/en/products/process-equipment"
                      state={{
                        from: "c73508c53332396351000317",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      Process Equipment
                    </CustomLink>
                    <CustomLink
                      title="Modules & Skids"
                      to="/en/products/modules-skids"
                      state={{
                        from: "288382cd30386596a9000382",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      Modules & Skids
                    </CustomLink>
                    <CustomLink
                      title="Heat Transfer Equipment"
                      to="/en/products/heat-transfer-equipment"
                      state={{
                        from: "28913a11373533d135000233",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      Heat Transfer Equipment
                    </CustomLink>
                    <CustomLink
                      title="Storage Tanks"
                      to="/en/products/storage-tanks"
                      state={{
                        from: "28982d2a646333b06c0001ee",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      Storage Tanks
                    </CustomLink>
                    <CustomLink
                      title="Pipeline Equipment"
                      to="/en/products/pipeline-equipment"
                      state={{
                        from: "289fa2c0353531373b0001ab",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      Pipeline Equipment
                    </CustomLink>
                    <CustomLink
                      title="Rubber & PU Products"
                      to="/en/products/rubber-pu-products"
                      state={{
                        from: "28a36f2438663210cf0001f7",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      Rubber & PU Products
                    </CustomLink>
                    <CustomLink
                      title="Product References"
                      to="/en/products/product-references"
                      state={{
                        from: "28aa8d2a39316191ae000398",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      Product References
                    </CustomLink>
                  </div>
                </div>
                <div className="AAA">
                  <div className="Parent">
                    <CustomLink
                      title="Careers"
                      to="/en/careers/working-at-arabian-industries"
                      state={{
                        from: "4693fc26653038c49600007b",
                        catSEND: "pages",
                      }}
                      className="Parent"
                    >
                      Careers
                    </CustomLink>
                  </div>
                  <div className="Child">
                    <CustomLink
                      title="Working at Arabian Industries"
                      to="/en/careers/working-at-arabian-industries"
                      state={{
                        from: "4693fc26653038c49600007b",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      Working at Arabian Industries
                    </CustomLink>
                    <CustomLink
                      title="Our People"
                      to="/en/careers/our-people"
                      state={{
                        from: "4699ad9863336641d50000ce",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      Our People
                    </CustomLink>
                    <CustomLink
                      title="Human Resource Partners"
                      to="/en/careers/human-resource-partners"
                      state={{
                        from: "469db8f7313333f199000179",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      Human Resource Partners
                    </CustomLink>
                    <CustomLink
                      title="Localisation"
                      to="/en/careers/localisation"
                      state={{
                        from: "46a103cc6637363f01000161",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      Localisation
                    </CustomLink>
                    <CustomLink
                      title="Job Openings"
                      to="/en/careers/job-openings"
                      state={{
                        from: "46a435bc343038290f0002ac",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      Job Openings
                    </CustomLink>
                    <CustomLink
                      title="Verify Job Offers"
                      to="/en/careers/verify-job-offers"
                      state={{
                        from: "ae7bec353761347bbe0002dc",
                        catSEND: "pages",
                      }}
                      className="Parent"
                    >
                      Verify Job Offers
                    </CustomLink>
                  </div>
                </div>
              </div>
              {/*  */}
              <div className="BlockF MIDD">
                <div className="AA">
                  <div className="Parent">
                    <CustomLink
                      title="Markets"
                      to="/en/markets/overview"
                      state={{
                        from: "4467b3fe653339432a0000b6",
                        catSEND: "pages",
                      }}
                      className="Parent"
                    >
                      Markets
                    </CustomLink>
                  </div>
                  <div className="Child">
                    <CustomLink
                      title="Overview"
                      to="/en/markets/overview"
                      state={{
                        from: "4467b3fe653339432a0000b6",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      Overview
                    </CustomLink>
                    <CustomLink
                      title="Conventional Energy"
                      to="/en/markets/conventional-energy"
                      state={{
                        from: "450085ee6461391a3c0003c6",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      Conventional Energy
                    </CustomLink>
                    <CustomLink
                      title="New Energy"
                      to="/en/markets/new-energy"
                      state={{
                        from: "4660f4153533328081000290",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      New Energy
                    </CustomLink>
                    <CustomLink
                      title="Downstream"
                      to="/en/markets/downstream"
                      state={{
                        from: "46565c426563638c0100011c",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      Downstream
                    </CustomLink>
                    <CustomLink
                      title="Utilities"
                      to="/en/markets/utilities"
                      state={{
                        from: "465d917c3734337e8500023b",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      Utilities
                    </CustomLink>
                    <CustomLink
                      title="Metals & Industrial"
                      to="/en/markets/metals-industrial"
                      state={{
                        from: "450bc1203239300e9a000378",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      Metals & Industrial
                    </CustomLink>
                  </div>
                </div>
                <div className="AAA">
                  <div className="Parent">
                    <CustomLink
                      title="Media"
                      to="/en/media/news"
                      state={{
                        from: "46a7945638633840b8000011",
                        catSEND: "pages",
                      }}
                      className="Parent"
                    >
                      Media
                    </CustomLink>
                  </div>
                  <div className="Child">
                    <CustomLink
                      title="News"
                      to="/en/media/news"
                      state={{
                        from: "46a7945638633840b8000011",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      News
                    </CustomLink>
                    <CustomLink
                      title="Downloads"
                      to="/en/media/downloads"
                      state={{
                        from: "46acb65a3539334b2c0001a7",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      Downloads
                    </CustomLink>
                  </div>
                </div>
              </div>
              {/*  */}
              <div className="BlockF MIDD">
                <div className="AA">
                  <div className="Parent">
                    <CustomLink
                      title="Responsibility"
                      to="/en/responsibility/hse"
                      state={{
                        from: "467ed11c3661326c150001d3",
                        catSEND: "pages",
                      }}
                      className="Parent"
                    >
                      Responsibility
                    </CustomLink>
                  </div>
                  <div className="Child">
                    <CustomLink
                      title="HSE"
                      to="/en/responsibility/hse"
                      state={{
                        from: "467ed11c3661326c150001d3",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      HSE
                    </CustomLink>
                    <CustomLink
                      title="In-Country Value"
                      to="/en/responsibility/in-country-value"
                      state={{
                        from: "468486f83930372e7d000042",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      In-Country Value
                    </CustomLink>
                    <CustomLink
                      title="Sustainability"
                      to="/en/responsibility/sustainability"
                      state={{
                        from: "46878d5b3736627da5000169",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      Sustainability
                    </CustomLink>
                  </div>
                </div>
                <div className="AAA">
                  <div className="Parent">
                    <CustomLink
                      style={{
                        color: "white",
                      }}
                      title="Contact us"
                      to="/en/contact-us"
                      state={{
                        from: "c18ff0253436318a9f00033c",
                        catSEND: "pages",
                      }}
                      className="Parent"
                    >
                      Contact us
                    </CustomLink>
                    <div
                      onClick={handleClick}
                      className="site-search-footer"
                      width="25"
                      height="25"
                      alt="Arabian Industries LLC | Search Here"
                      title="Arabian Industries LLC | Search Here"
                    ></div>
                  </div>
                </div>
              </div>
            </ul>

            {/*  */}
            <div className="FooterCopyBar">
              <div
                className="CopyBarF"
                style={{
                  color: "#D1E4F3",
                  fontSize: "14px",
                }}
              >
                © {new Date().getFullYear()} Arabian Industries. All rights
                reserved.
              </div>
              <div className="RemainLinks">
                <div className="BlocksinFoot">
                  <div>
                    <Link
                      to="/en/privacy_policy"
                      className="Parent"
                      title="Privacy Policy"
                    >
                      Privacy Policy
                    </Link>
                  </div>
                  {post.footer_download.map((item, index) => (
                    <div key={index}>
                      <a
                        className="Parent"
                        href={process.env.REACT_APP_IMG + item.value.image.path}
                        target="_blank"
                        title={item.value.title}
                      >
                        {item.value.title}
                      </a>
                    </div>
                  ))}
                </div>
                <div className="BlocksinFoot"></div>
                <div className="BlocksinFoot">
                  <a
                    style={{
                      color: "#a0a2ce",
                    }}
                    title="Call +968 2205 6000"
                    href="tel:+968 2205 6000"
                  >
                    +968 2205 6000
                  </a>
                  <br></br>P.O. Box 51, Postal Code 124, Muscat, Sultanate of
                  Oman
                </div>
              </div>
            </div>
          </div>

          {/* <div className="BGEffectFooter"></div> */}
          <img src={BGEffectsFOOT} className="BGEffectFooterTOP" />
        </footer>
      ) : (
        // ARABIC FOOTER
        <footer className="footer arabic">
          <div className="FooterBin">
            <div className="TOPFoot">
              <div className="FLeft">
                <img
                  className="site-logo-loading"
                  width="200"
                  height="80"
                  src={LogoW}
                  alt="Arabian Industries LLC | Oman"
                  title="Arabian Industries LLC | Oman"
                />
              </div>
              <div className="FRight">
                <a
                  href="https://www.linkedin.com/company/arabian-industries/"
                  target="_blank"
                  title="https://www.linkedin.com/company/arabian-industries/"
                >
                  <div
                    className="linkedin"
                    width="25"
                    height="25"
                    alt="Arabian Industries LLC | Linkedin"
                    title="Arabian Industries LLC | Linkedin"
                  ></div>
                </a>
              </div>
            </div>

            {/* FOOTER MENU */}
            <ul className="FootMenu">
              <div className="BlockF">
                <div className="Parent">
                  <CustomLink
                    title="من نحن"
                    to="/ar/who-we-are/what-we-do"
                    state={{
                      from: "8013fcbe31643963a10000ee",
                      catSEND: "pages",
                    }}
                    className="Parent"
                  >
                    من نحن
                  </CustomLink>
                </div>
                <div className="Child">
                  <CustomLink
                    title="الذي نفعله"
                    to="/ar/who-we-are/what-we-do"
                    state={{
                      from: "8013fcbe31643963a10000ee",
                      catSEND: "pages",
                    }}
                    className="Child"
                  >
                    الذي نفعله
                  </CustomLink>
                  <CustomLink
                    title="قصتنا"
                    to="/ar/who-we-are/our-story"
                    state={{
                      from: "80a07ac565346454d000016a",
                      catSEND: "pages",
                    }}
                    className="Child"
                  >
                    قصتنا
                  </CustomLink>
                  <CustomLink
                    title="مجموعة شركات"
                    to="/ar/group_companies/overview"
                    state={{
                      from: "8257ecbf363066b867000236",
                      catSEND: "group_companies",
                    }}
                    className="Parent Last"
                  >
                    مجموعة شركات
                  </CustomLink>
                  <div className="subHolder">
                    <CustomLink
                      title="ملخص"
                      to="/ar/group_companies/overview"
                      state={{
                        from: "8257ecbf363066b867000236",
                        catSEND: "group_companies",
                      }}
                      className="ParentSub"
                    >
                      ملخص
                    </CustomLink>
                    <CustomLink
                      title="AI Clad LLC"
                      to="/ar/group_companies/ai-clad-llc"
                      state={{
                        from: "829ac6af36376170980001ad",
                        catSEND: "group_companies",
                      }}
                      className="ParentSub"
                    >
                      AI Clad LLC
                    </CustomLink>
                    <CustomLink
                      title="الصناعات العربية للتصنيع ش.م.م (AIM)"
                      to="/ar/group_companies/arabian-industries-manufacturing-llc-aim"
                      state={{
                        from: "b3539f91613837ba97000143",
                        catSEND: "group_companies",
                      }}
                      className="ParentSub"
                    >
                      الصناعات العربية للتصنيع ش.م.م (AIM)
                    </CustomLink>
                    <CustomLink
                      title="مشاريع الصناعات العربية ذ.م.م"
                      to="/ar/group_companies/arabian-industries-projects-llc"
                      state={{
                        from: "bfcbb3733266614e3d000247",
                        catSEND: "group_companies",
                      }}
                      className="ParentSub"
                    >
                      مشاريع الصناعات العربية ذ.م.م
                    </CustomLink>
                    <CustomLink
                      title="الصناعات العربية للدعم الفني ذ.م.م"
                      to="/ar/group_companies/arabian-industries-technical-support-llc"
                      state={{
                        from: "bfd8d29b353436546d000061",
                        catSEND: "group_companies",
                      }}
                      className="ParentSub"
                    >
                      الصناعات العربية للدعم الفني ذ.م.م
                    </CustomLink>
                    <CustomLink
                      title="المشاريع المشتركة للصناعات العربية"
                      to="/ar/group_companies/arabian-industries-joint-ventures"
                      state={{
                        from: "bfefd6ec33613080740000b5",
                        catSEND: "group_companies",
                      }}
                      className="ParentSub"
                    >
                      المشاريع المشتركة للصناعات العربية
                    </CustomLink>
                    <CustomLink
                      title="المطاط الفني ذ م م (NEKTEL)"
                      to="/ar/group_companies/technical-rubber-llc-nektel"
                      state={{
                        from: "c306fb79613530fe930002df",
                        catSEND: "group_companies",
                      }}
                      className="ParentSub"
                    >
                      المطاط الفني ذ م م (NEKTEL)
                    </CustomLink>
                    <CustomLink
                      title="شركة الإنشاءات العمانية (توكو) ش.م.م"
                      to="/ar/group_companies/the-oman-construction-company-toco-llc"
                      state={{
                        from: "c32162513138312a8c0003d0",
                        catSEND: "group_companies",
                      }}
                      className="ParentSub"
                    >
                      شركة الإنشاءات العمانية (توكو) ش.م.م
                    </CustomLink>
                  </div>
                </div>
                <div className="Child">
                  <CustomLink
                    title="الموارد والبنية التحتية"
                    to="/ar/who-we-are/resources-infrastructure"
                    state={{
                      from: "80ef2576633532f4500001b2",
                      catSEND: "pages",
                    }}
                    className="Child"
                  >
                    الموارد والبنية التحتية
                  </CustomLink>
                  <CustomLink
                    title="رؤيتنا وقيمنا"
                    to="/ar/who-we-are/our-vision-values"
                    state={{
                      from: "80fb3fb1623963970b00002d",
                      catSEND: "pages",
                    }}
                    className="Child"
                  >
                    رؤيتنا وقيمنا
                  </CustomLink>
                  <CustomLink
                    title="الجوائز والاعتمادات"
                    to="/ar/who-we-are/awards-accreditations"
                    state={{
                      from: "811c97616464370b8000016f",
                      catSEND: "pages",
                    }}
                    className="Child"
                  >
                    الجوائز والاعتمادات
                  </CustomLink>
                </div>
              </div>
              {/*  */}
              <div className="BlockF">
                <div className="Parent">
                  <CustomLink
                    title="خدمات"
                    to="/ar/services/overview"
                    state={{
                      from: "b40f44d8393339c2d500010f",
                      catSEND: "pages",
                    }}
                    className="Parent"
                  >
                    خدمات
                  </CustomLink>
                </div>
                <div className="Child">
                  <CustomLink
                    title="ملخص"
                    to="/ar/services/overview"
                    state={{
                      from: "b40f44d8393339c2d500010f",
                      catSEND: "pages",
                    }}
                    className="Child"
                  >
                    ملخص
                  </CustomLink>

                  <CustomLink
                    title="EPC خدمات"
                    to="/ar/epc_services/overview"
                    state={{
                      from: "b9e5d065366131aef9000144",
                      catSEND: "epc_services",
                    }}
                    className="Parent Last"
                  >
                    EPC خدمات
                  </CustomLink>
                  <div className="subHolder">
                    <CustomLink
                      title="مخلص"
                      to="/ar/epc_services/overview"
                      state={{
                        from: "b9e5d065366131aef9000144",
                        catSEND: "epc_services",
                      }}
                      className="ParentSub"
                    >
                      مخلص
                    </CustomLink>
                    <CustomLink
                      title="الخدمات الهندسية والمشتريات"
                      to="/ar/epc_services/engineering-procurement-services"
                      state={{
                        from: "3603db416432344bae000013",
                        catSEND: "epc_services",
                      }}
                      className="ParentSub"
                    >
                      الخدمات الهندسية والمشتريات
                    </CustomLink>
                    <CustomLink
                      title="خدمات البناء"
                      to="/ar/epc_services/construction-services"
                      state={{
                        from: "581dde7d32356468ef000115",
                        catSEND: "epc_services",
                      }}
                      className="ParentSub"
                    >
                      خدمات البناء
                    </CustomLink>
                    <CustomLink
                      title="EPC لخطوط الأنابيب"
                      to="/ar/epc_services/epc-of-pipelines"
                      state={{
                        from: "5931a1ed383230ec45000329",
                        catSEND: "epc_services",
                      }}
                      className="ParentSub"
                    >
                      EPC لخطوط الأنابيب
                    </CustomLink>
                    <CustomLink
                      title="خدمات EPCC البحرية"
                      to="/ar/epc_services/offshore-epcc-services"
                      state={{
                        from: "5aac4d39653131aa29000284",
                        catSEND: "epc_services",
                      }}
                      className="ParentSub"
                    >
                      خدمات EPCC البحرية
                    </CustomLink>
                    <CustomLink
                      title="خدمات براونفيلد"
                      to="/ar/epc_services/brownfield-services"
                      state={{
                        from: "5b48f8a9306630835200018c",
                        catSEND: "epc_services",
                      }}
                      className="ParentSub"
                    >
                      خدمات براونفيلد
                    </CustomLink>
                    <CustomLink
                      title="التكليف وبدء التشغيل"
                      to="/ar/epc_services/commissioning-start-up"
                      state={{
                        from: "5c272ec232313935ff000045",
                        catSEND: "epc_services",
                      }}
                      className="ParentSub"
                    >
                      التكليف وبدء التشغيل
                    </CustomLink>
                  </div>
                </div>
                <div className="Child">
                  <CustomLink
                    title="الصيانة وسلامة الأصول"
                    to="/ar/services/maintenance-asset-integrity"
                    state={{
                      from: "b427dcb33932663bcb00006b",
                      catSEND: "pages",
                    }}
                    className="Child"
                  >
                    الصيانة وسلامة الأصول
                  </CustomLink>
                  <CustomLink
                    title="عمليات الإغلاق والتحول"
                    to="/ar/services/shutdowns-turnarounds"
                    state={{
                      from: "b436d32261613966970003cd",
                      catSEND: "pages",
                    }}
                    className="Child"
                  >
                    عمليات الإغلاق والتحول
                  </CustomLink>
                  <CustomLink
                    title="خدمات الورشة"
                    to="/ar/workshop_services/overview"
                    state={{
                      from: "b44cef9436396485e20003bc",
                      catSEND: "workshop_services",
                    }}
                    className="Parent Last"
                  >
                    خدمات الورشة
                  </CustomLink>
                  <div className="subHolder">
                    <CustomLink
                      title="ملخص"
                      to="/ar/workshop_services/overview"
                      state={{
                        from: "b44cef9436396485e20003bc",
                        catSEND: "workshop_services",
                      }}
                      className="ParentSub"
                    >
                      ملخص
                    </CustomLink>
                    <CustomLink
                      title="خدمات التصنيع"
                      to="/ar/workshop_services/fabrication-services"
                      state={{
                        from: "63d54531386363cf33000041",
                        catSEND: "workshop_services",
                      }}
                      className="ParentSub"
                    >
                      خدمات التصنيع
                    </CustomLink>
                    <CustomLink
                      title="خدمات الطلاء والبطانة"
                      to="/ar/workshop_services/coating-lining-services"
                      state={{
                        from: "646c2766366463cb0a000390",
                        catSEND: "workshop_services",
                      }}
                      className="ParentSub"
                    >
                      خدمات الطلاء والبطانة
                    </CustomLink>
                    <CustomLink
                      title="CRA لحام تراكب الكسوة"
                      to="/ar/workshop_services/cra-weld-overlay-cladding"
                      state={{
                        from: "65481f336639362f3d000231",
                        catSEND: "workshop_services",
                      }}
                      className="ParentSub"
                    >
                      CRA لحام تراكب الكسوة
                    </CustomLink>
                    <CustomLink
                      title="صيانة معدات حقول النفط"
                      to="/ar/workshop_services/oilfield-equipment-maintenance"
                      state={{
                        from: "65cce55e613462f53f000080",
                        catSEND: "workshop_services",
                      }}
                      className="ParentSub"
                    >
                      صيانة معدات حقول النفط
                    </CustomLink>
                    <CustomLink
                      title="صيانة الصمام"
                      to="/ar/workshop_services/valve-maintenance"
                      state={{
                        from: "664bd90c6539374d4000003e",
                        catSEND: "workshop_services",
                      }}
                      className="ParentSub"
                    >
                      صيانة الصمام
                    </CustomLink>
                    <CustomLink
                      title="خدمات صيانة المعدات"
                      to="/ar/workshop_services/equipment-maintenance-services"
                      state={{
                        from: "6714d0363036392b010000a9",
                        catSEND: "workshop_services",
                      }}
                      className="ParentSub"
                    >
                      خدمات صيانة المعدات
                    </CustomLink>
                    <CustomLink
                      title="إصلاح واختبار ومعايرة الأدوات"
                      to="/ar/workshop_services/repair-testing-calibration-of-instruments"
                      state={{
                        from: "67aad654646331adbf000346",
                        catSEND: "workshop_services",
                      }}
                      className="ParentSub"
                    >
                      إصلاح واختبار ومعايرة الأدوات
                    </CustomLink>
                  </div>
                  <CustomLink
                    title="الخدمات اللوجستية ونقل الحفارات"
                    to="/ar/services/logistics-rig-moving-services"
                    state={{
                      from: "ba2f4a27653739803f00031d",
                      catSEND: "pages",
                    }}
                    className="ParentSub"
                  >
                    الخدمات اللوجستية ونقل الحفارات
                  </CustomLink>
                  <CustomLink
                    title="خدمات الدعم"
                    to="/ar/services/support-services"
                    state={{
                      from: "ba52c2833536632ea600032d",
                      catSEND: "pages",
                    }}
                    className="ParentSub"
                  >
                    خدمات الدعم
                  </CustomLink>
                  <CustomLink
                    title="دراسات الحالة"
                    to="/ar/services/case-studies"
                    state={{
                      from: "ba5d7f7233343489810001cd",
                      catSEND: "pages",
                    }}
                    className="ParentSub"
                  >
                    دراسات الحالة
                  </CustomLink>
                </div>
              </div>
              {/* Arabic Footer First */}
              <div className="BlockF MIDD">
                <div className="AA">
                  <div className="Parent">
                    <CustomLink
                      title="منتجاتنا"
                      to="/ar/products/overview"
                      state={{
                        from: "bb490807303638e3d500029d",
                        catSEND: "pages",
                      }}
                      className="Parent"
                    >
                      منتجاتنا
                    </CustomLink>
                  </div>
                  <div className="Child">
                    <CustomLink
                      title="ملخص"
                      to="/ar/products/overview"
                      state={{
                        from: "bb490807303638e3d500029d",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      ملخص
                    </CustomLink>
                    <CustomLink
                      title="معدات العملية"
                      to="/ar/products/process-equipment"
                      state={{
                        from: "c73508c53332396351000317",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      معدات العملية
                    </CustomLink>
                    <CustomLink
                      title="الوحدات والانزلاقات"
                      to="/ar/products/modules-skids"
                      state={{
                        from: "288382cd30386596a9000382",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      الوحدات والانزلاقات
                    </CustomLink>
                    <CustomLink
                      title="معدات نقل الحرارة"
                      to="/ar/products/heat-transfer-equipment"
                      state={{
                        from: "28913a11373533d135000233",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      معدات نقل الحرارة
                    </CustomLink>
                    <CustomLink
                      title="صهاريج التخزين"
                      to="/ar/products/storage-tanks"
                      state={{
                        from: "28982d2a646333b06c0001ee",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      صهاريج التخزين
                    </CustomLink>
                    <CustomLink
                      title="معدات خطوط الأنابيب"
                      to="/ar/products/pipeline-equipment"
                      state={{
                        from: "289fa2c0353531373b0001ab",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      معدات خطوط الأنابيب
                    </CustomLink>
                    <CustomLink
                      title="منتجات المطاط والبولي يوريثان"
                      to="/ar/products/rubber-pu-products"
                      state={{
                        from: "28a36f2438663210cf0001f7",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      منتجات المطاط والبولي يوريثان
                    </CustomLink>
                    <CustomLink
                      title="مراجع المنتج"
                      to="/ar/products/product-references"
                      state={{
                        from: "28aa8d2a39316191ae000398",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      مراجع المنتج
                    </CustomLink>
                  </div>
                </div>
                <div className="AAA">
                  <div className="Parent">
                    <CustomLink
                      title="وظائف"
                      to="/ar/careers/working-at-arabian-industries"
                      state={{
                        from: "4693fc26653038c49600007b",
                        catSEND: "pages",
                      }}
                      className="Parent"
                    >
                      وظائف
                    </CustomLink>
                  </div>
                  <div className="Child">
                    <CustomLink
                      title="العمل في الصناعات العربية"
                      to="/ar/careers/working-at-arabian-industries"
                      state={{
                        from: "4693fc26653038c49600007b",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      العمل في الصناعات العربية
                    </CustomLink>
                    <CustomLink
                      title="موظفينا"
                      to="/ar/careers/our-people"
                      state={{
                        from: "4699ad9863336641d50000ce",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      موظفينا
                    </CustomLink>
                    <CustomLink
                      title="شركاء الموارد البشرية"
                      to="/ar/careers/human-resource-partners"
                      state={{
                        from: "469db8f7313333f199000179",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      شركاء الموارد البشرية
                    </CustomLink>
                    <CustomLink
                      title="التعمين"
                      to="/ar/careers/localisation"
                      state={{
                        from: "46a103cc6637363f01000161",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      التعمين
                    </CustomLink>
                    <CustomLink
                      title="وظائف شاغرة"
                      to="/ar/careers/job-openings"
                      state={{
                        from: "46a435bc343038290f0002ac",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      وظائف شاغرة
                    </CustomLink>
                    <CustomLink
                      title="إختبار عرض العمل"
                      to="/ar/careers/verify-job-offers"
                      state={{
                        from: "ae7bec353761347bbe0002dc",
                        catSEND: "pages",
                      }}
                      className="Parent"
                    >
                      إختبار عرض العمل
                    </CustomLink>
                  </div>
                </div>
              </div>
              {/*  */}
              <div className="BlockF MIDD">
                <div className="AA">
                  <div className="Parent">
                    <CustomLink
                      title="السوق"
                      to="/ar/markets/overview"
                      state={{
                        from: "4467b3fe653339432a0000b6",
                        catSEND: "pages",
                      }}
                      className="Parent"
                    >
                      السوق
                    </CustomLink>
                  </div>
                  <div className="Child">
                    <CustomLink
                      title="ملخص"
                      to="/ar/markets/overview"
                      state={{
                        from: "4467b3fe653339432a0000b6",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      ملخص
                    </CustomLink>
                    <CustomLink
                      title="الطاقة التقليدية"
                      to="/ar/markets/conventional-energy"
                      state={{
                        from: "450085ee6461391a3c0003c6",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      الطاقة التقليدية
                    </CustomLink>
                    <CustomLink
                      title="الطاقة الجديدة"
                      to="/ar/markets/new-energy"
                      state={{
                        from: "4660f4153533328081000290",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      الطاقة الجديدة
                    </CustomLink>
                    <CustomLink
                      title="المصب"
                      to="/ar/markets/downstream"
                      state={{
                        from: "46565c426563638c0100011c",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      المصب
                    </CustomLink>
                    <CustomLink
                      title="خدمات"
                      to="/ar/markets/utilities"
                      state={{
                        from: "465d917c3734337e8500023b",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      خدمات
                    </CustomLink>
                    <CustomLink
                      title="المعادن والصناعية"
                      to="/ar/markets/metals-industrial"
                      state={{
                        from: "450bc1203239300e9a000378",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      المعادن والصناعية
                    </CustomLink>
                  </div>
                </div>
                <div className="AAA">
                  <div className="Parent">
                    <CustomLink
                      title="الوسائط"
                      to="/ar/media/news"
                      state={{
                        from: "46a7945638633840b8000011",
                        catSEND: "pages",
                      }}
                      className="Parent"
                    >
                      الوسائط
                    </CustomLink>
                  </div>
                  <div className="Child">
                    <CustomLink
                      title="الأخبار"
                      to="/ar/media/news"
                      state={{
                        from: "46a7945638633840b8000011",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      الأخبار
                    </CustomLink>
                    <CustomLink
                      title="التحميلات"
                      to="/ar/media/downloads"
                      state={{
                        from: "46acb65a3539334b2c0001a7",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      التحميلات
                    </CustomLink>
                  </div>
                </div>
              </div>
              {/*  */}
              <div className="BlockF MIDD">
                <div className="AA">
                  <div className="Parent">
                    <CustomLink
                      title="المسؤلية"
                      to="/ar/responsibility/hse"
                      state={{
                        from: "467ed11c3661326c150001d3",
                        catSEND: "pages",
                      }}
                      className="Parent"
                    >
                      المسؤلية
                    </CustomLink>
                  </div>
                  <div className="Child">
                    <CustomLink
                      title="الصحة والسلامة والبيئة"
                      to="/ar/responsibility/hse"
                      state={{
                        from: "467ed11c3661326c150001d3",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      الصحة والسلامة والبيئة
                    </CustomLink>
                    <CustomLink
                      title="القيمة المحلية"
                      to="/ar/responsibility/in-country-value"
                      state={{
                        from: "468486f83930372e7d000042",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      القيمة المحلية
                    </CustomLink>
                    <CustomLink
                      title="الاستدامة"
                      to="/ar/responsibility/sustainability"
                      state={{
                        from: "46878d5b3736627da5000169",
                        catSEND: "pages",
                      }}
                      className="Child"
                    >
                      الاستدامة
                    </CustomLink>
                  </div>
                </div>
                <div className="AAA">
                  <div className="Parent">
                    <CustomLink
                      style={{
                        color: "white",
                      }}
                      title="تواصل معنا"
                      to="/ar/contact-us"
                      state={{
                        from: "c18ff0253436318a9f00033c",
                        catSEND: "pages",
                      }}
                      className="Parent"
                    >
                      تواصل معنا
                    </CustomLink>
                    <div
                      onClick={handleClick}
                      className="site-search-footer"
                      width="25"
                      height="25"
                      alt="Arabian Industries LLC | Search Here"
                      title="Arabian Industries LLC | Search Here"
                    ></div>
                  </div>
                </div>
              </div>
            </ul>

            {/* Arabic Footer Second*/}
            <div className="FooterCopyBar arabic">
              <div
                className="CopyBarF"
                style={{
                  color: "#D1E4F3",
                  fontSize: "14px",
                }}
              >
                © {new Date().getFullYear()} الصناعات العربية. جميع الحقوق
                محفوظة.
              </div>
              <div className="RemainLinks">
                <div className="BlocksinFoot">
                  <div>
                    <Link
                      to="/ar/privacy_policy"
                      className="Parent"
                      title="Privacy Policy"
                    >
                      سياسة الخصوصية
                    </Link>
                  </div>
                  {post.footer_download.map((item, index) => (
                    <div key={index}>
                      <a
                        className="Parent"
                        href={process.env.REACT_APP_IMG + item.value.image.path}
                        target="_blank"
                        title={item.value.title_ar}
                      >
                        {item.value.title_ar}
                      </a>
                    </div>
                  ))}
                </div>
                <div className="BlocksinFoot"></div>
                <div className="BlocksinFoot">
                  <a
                    style={{
                      color: "#a0a2ce",
                    }}
                    title="Call +968 2205 6000"
                    href="tel:+968 2205 6000"
                  >
                    0096822056000
                  </a>
                  <br></br>ص.ب. ص.ب 51، الرمز البريدي 124، مسقط، سلطنة عمان
                </div>
              </div>
            </div>
          </div>

          {/* <div className="BGEffectFooter"></div> */}
          <img src={BGEffectsFOOT} className="BGEffectFooterTOP" />
        </footer>
      )}
    </>
  );
}

export default App;

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
}
