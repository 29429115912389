import React, { useEffect, useState } from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import MobileCollection from "./mobileCollectionPop";
import { Disclosure } from "@headlessui/react";

const Menu = (props) => {
  const [data, setUsers] = useState([null]);
  const [parent, setParent] = useState([null]);

  const [isLoading, setIsLoading] = useState(true);
  const ASSETS = "https://admin.arabian-industries.net/api/storage/uploads";

  //console.log(props.page);

  // MobileMenu
  function refreshPage() {
    var delayInMilliseconds = 1000; //1 second
    setTimeout(function () {
      window.location.reload(false);
    }, delayInMilliseconds);
  }
  //

  const fetchUserData = () => {
    fetch(
      "https://admin.arabian-industries.net/api/api/collections/entry/pages/" +
        props.page +
        "?token=8c25a7a0819fe42e3a0ddb3adc68b1"
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        //console.log(data);
        setUsers(data);
        // LOAD SEC
        fetch(
          "https://admin.arabian-industries.net/api/api/collections/entry/pages/" +
            data._pid +
            "?token=8c25a7a0819fe42e3a0ddb3adc68b1"
        )
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            //console.log(data);
            setParent(data);
          });
        //
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <>
      {isLoading ? (
        <div
          className="loading"
          style={{
            position: "relative",
            height: "50vh",
            display: "flex",
            alignItems: "center",
          }}
        ></div>
      ) : (
        <>
          <div className="MobileDataHolder MOB">
            {data.collection ? (
              <React.Fragment>
                {/* <Link
                  to={`/en/` + data.title_slug}
                   state={{ from: data._id }}
                   title={data.title}
                 >
                   <div className="MenuIcon">
                     <div className="MobileH1">
                       {data.title} <span></span>
                     </div>
                   </div>
                </Link> */}

                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button>
                        {data.title}
                        <span className={open ? "open" : "close"}>&#8595;</span>
                      </Disclosure.Button>
                      <Disclosure.Panel>
                        <MobileCollection page={data._id} />
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="PopUpData">
                  <div className="PopUpLeft">
                    {/* <h4 className="PopH4">{data.title}</h4>
                    <div
                      className="PopText"
                      dangerouslySetInnerHTML={{
                        __html: data.content.substring(0, 150) + `...`,
                      }}
                    /> */}

                    <Link
                      onClick={refreshPage}
                      key={data.title_slug}
                      className="btnSendMOB"
                      state={{ from: data._id, catSEND: "pages" }}
                      to={`/en/` + parent.title_slug + `/` + data.title_slug}
                      title={`Find out more about ` + data.title}
                      style={{
                        marginBottom: "0px",
                        marginTop: "0px",
                      }}
                    >
                      {data.title}
                    </Link>
                  </div>
                  {/* <div className="PopUpRight">
                    {data.image_menu ? (
                      <img
                        title={data.title}
                        src={ASSETS + data.image_menu.path}
                        alt={data.title}
                        className="MenuImgPOP"
                        width="105"
                        height="160"
                        loading="lazy"
                        decoding="async"
                      />
                    ) : (
                      <img
                        title={data.title}
                        src={ASSETS + data.image_mobile.path}
                        alt={data.title}
                        className="MenuImgPOP"
                        width="105"
                        height="160"
                        loading="lazy"
                        decoding="async"
                      />
                    )}
                  </div> */}
                </div>
              </React.Fragment>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Menu;

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
}
