import React, { useEffect, useState } from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import { Tab } from "@headlessui/react";

const Menu = (props) => {
  const [data, setUsers] = useState([]);
  const [collection, setCollection] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const ASSETS = "https://admin.arabian-industries.net/api/storage/uploads";

  function replaceSpaceWithUnderscore(str) {
    return str.replaceAll(" ", "_");
  }
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  // MobileMenu
  function refreshPage() {
    var delayInMilliseconds = 1000; //1 second
    setTimeout(function () {
      window.location.reload(false);
    }, delayInMilliseconds);
  }
  //

  const fetchUserData = () => {
    fetch(
      "https://admin.arabian-industries.net/api/api/collections/entry/pages/" +
        props.page +
        "?token=8c25a7a0819fe42e3a0ddb3adc68b1"
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        //console.log(data);
        setUsers(data);
        //
        if (data.collection) {
          // LOAD Collection
          fetch(
            "https://admin.arabian-industries.net/api/api/collections/get/" +
              replaceSpaceWithUnderscore(data.collection.toLowerCase()) +
              "?token=8c25a7a0819fe42e3a0ddb3adc68b1"
          )
            .then((response) => {
              return response.json();
            })
            .then((data) => {
              //console.log(data);
              setCollection(data.entries);
              setIsLoading(false);
            });
          //
        } else {
          // LOAD Collection
          fetch(
            "https://admin.arabian-industries.net/api/api/collections/get/pages?token=8c25a7a0819fe42e3a0ddb3adc68b1&filter[_pid]=" +
              props.page
          )
            .then((response) => {
              return response.json();
            })
            .then((data) => {
              //console.log(data);
              setCollection(data.entries);
              setIsLoading(false);
            });
          //
        }
        //
      });
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <>
      {isLoading ? (
        <div
          className="loading"
          style={{
            position: "relative",
            height: "50vh",
            display: "flex",
            alignItems: "center",
          }}
        ></div>
      ) : (
        <React.Fragment key={props.page}>
          <div className="CollectionHolder ">
            {data.collection ? (
              <Tab.Group>
                <div className="CollectionLeft">
                  {collection &&
                    collection.map((item, index) => (
                      <Link
                        onClick={refreshPage}
                        key={item.title_slug}
                        className="btnSendMOB"
                        state={{
                          from: item._id,
                          catSEND: replaceSpaceWithUnderscore(
                            data.collection.toLowerCase()
                          ),
                        }}
                        to={
                          `/ar/` +
                          replaceSpaceWithUnderscore(
                            data.collection.toLowerCase()
                          ) +
                          `/` +
                          item.title_slug
                        }
                        title={`اكتشف المزيد ` + item.title_ar}
                      >
                        {item.title_ar}
                      </Link>
                    ))}
                </div>
              </Tab.Group>
            ) : (
              <div></div>
            )}
          </div>
        </React.Fragment>
      )}
    </>
  );
};

export default Menu;

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
}
