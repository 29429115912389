import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  SearchBox,
  Hits,
  Highlight,
  RefinementList,
  Pagination,
} from "react-instantsearch";
import "instantsearch.css/themes/satellite.css";
import "./style.css";

const searchClient = algoliasearch(
  "OYAGGFQ6TI",
  "44e2842df6efb4f96c2fefd3d696b8b4"
);

function Hit({ hit }) {
  return (
    <div style={{ display: "flex" }}>
      <div>
        <a href={hit.permalink}>
          <img src={hit.poster_path} style={{ width: "70px" }} alt={hit.name} />
        </a>
      </div>
      <div
        style={{
          marginLeft: "10px",
          display: "flex",
          alignItems: "top",
          flexDirection: "column",
        }}
      >
        <Highlight attribute="name" hit={hit} />
        <span style={{ fontWeight: "bolder" }}>
          {" "}
          <a href={hit.permalink}>{hit.title}</a>
        </span>

        <span style={{ width: "500px" }}>
          {" "}
          <a href={hit.permalink}>{hit.overview}</a>
        </span>
        <a href={hit.permalink}>
          <Highlight attribute="name" hit={hit} />
        </a>
      </div>
    </div>
  );
}

function App() {
  return (
    <div>
      <InstantSearch searchClient={searchClient} indexName="AREnglish">
        <SearchBox
          translations={{
            submitButtonTitle: "Envoyer",
          }}
        />
        <div style={{ overflow: "auto", marginTop: "70px" }}>
          <Hits hitComponent={Hit} />
          <Pagination />
        </div>
      </InstantSearch>
    </div>
  );
}

export default App;
