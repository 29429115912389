import React, { useEffect, useState } from "react";

import "./menu.css";

const Menu = (props) => {
  const [data, setUsers] = useState([]);

  const fetchUserData = () => {
    fetch(
      "https://admin.arabian-industries.net/api/api/collections/entry/pages/" +
        props.page +
        "?token=8c25a7a0819fe42e3a0ddb3adc68b1"
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        //console.log(data);
        setUsers(data);
      });
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <>
      <div>
        {data.collection ? (
          <div className="MenuIcon_ar">
            {props.name} <span></span>
          </div>
        ) : (
          <div>{props.name}</div>
        )}
      </div>
    </>
  );
};

export default Menu;
